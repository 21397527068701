<template>
  <div data-app>
    <v-card>
      <v-card-title>
        {{ cardTitle }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <!-- @click:row="handleClickItem" -->
      <v-data-table
        :headers="headers"
        :items="advanceCollection"
        :search="search"
        :sort-by="['date']"
        sort-desc="true"
        :loading="loadingTable"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <AdvancesModalForm
              :modalData="modalData"
              :permissions="permissions"
              :employeeCollection="employeeCollection"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></AdvancesModalForm>
            <v-spacer></v-spacer>
            <DeleteModalDialog
              :dialogDelete="dialogDelete"
              @closeDelete="handleCloseDelete"
              @deleteItemConfirm="handleDeleteItemConfirm"
            >
            </DeleteModalDialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- <router-link
            :to="`/inventory/quantityunits/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link> -->
          <v-icon
            v-if="permissionCan('delete')"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AdvancesModalForm, { initialFormData } from "./AdvancesModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

export const ENDPOINT = "humanResources/advance/";

export const PERMISSION_TO = "humanResources.employee.";

export default {
  name: "advances",
  components: { AdvancesModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "advances",
      routePath: "/humanResource/advances/",
      permissionTo: PERMISSION_TO,

      cardTitle: "Advances",
      search: "",
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Amount",
          value: "amount",
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      loadingTable: true,
      endPoint: ENDPOINT,
    };
  },

  computed: {
    ...mapGetters(["advanceCollection", "employeeCollection"]),
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchAdvance", "fetchEmployee"]),
    fetchModel() {
      this.fetchEmployee();
      return this.fetchAdvance();
    },
  },

  mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
